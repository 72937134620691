
import * as Components from "./Knight"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "knight"
}

