import React, { Component, PureComponent } from 'react';
import {NavLink, Link} from 'react-router-dom'

import './index.scss'



export class SiteNav extends PureComponent {
  render() {
    const pages = [
      {path: "/order", label: "Order"},
      // {path: "/specials", label: "Specials"},
      // {path: "/menu", label: "Menu"},
      // {path: "/catering", label: "Catering"},
      // {path: "/app", label: "App"},
      // {path: "/our-story", label: "Our Story"}

    ]
    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label}) => (
          <li><NavLink className="page-link" exact to={path}>{label}</NavLink></li>
        ))}
      </ul>
    </nav>
  )}
}

export const customOrderAppPaths = null //["/menu", "/order"]

export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">

          <div
            className="text">
            {/* <h2>Knight</h2> */}
            <p>Greek Mediterranean Cuisine</p>
            <Link
              className="order-online-link"
              to="/order">Order Online</Link>

            <p>Pickup & Delivery</p>
            <p>
              {/* <span className="cashback">with 5% rewards</span> */}
            </p>
          </div>


        </div>



      </div>
    );
  }
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header-wrapper">
        {/* <div className="ordering-header"> */}


      </div>
    );
  }
}
