import React from 'react'
import {Link} from 'react-router-dom'
import {getRestaurantId} from '../utils'
import Tips from '../Tips'
import OrderReceipt from './OrderReceipt'
import {injectShopContainer} from '../react/ShopDataContainer'
import ErrorBoundary from '../utils/ErrorBoundary'
import getEndpoint from '../utils/getEndpoint'
import Email from './Email'
import axios from 'axios'

const ORDER_DETAILS_ENDPOINT = getEndpoint('orderDetails')
// const ORDER_DETAILS_ENDPOINT = "https://ygvmunzxla.execute-api.us-west-1.amazonaws.com/v11-pretip-fix/orderDetails"


class OrderStatus extends React.Component {
  state = {
    order: {}
  };
  componentDidMount() {
    const orderId = this.props.orderId
    let shopId = this.props.shopId
    if (shopId === 'twindragonxmas') {
      shopId = 'twindragon'
    }
    axios.get(`${ORDER_DETAILS_ENDPOINT}/?r_id=${shopId}&order_id=${orderId}`)
      .then((response) => {

        this.setState({ order: response.data })
      }).catch((e) => {
        window.Sentry.captureException(e)
      })
  }

  render() {
    if (!this.props.shop) { return null }
    let { phone: restaurantPhone } = this.props.shop
    const order = this.state.order || {}
    const {orderAcceptedByShop, totals={}, tips, paymentType, cartType, } = order
    const {deliveryAddress,
            deliveryInstructions,
            distanceInMiles,
            unitNumber,
            items=[]
          } = order
    let {invoice} = totals
    let cartTypeText = (cartType === 'DELIVERY') ? "This is a Delivery Order" : "This is a Take Out Order"
    if (cartType === 'DINEIN') {cartTypeText = 'Dine In'}
    if (!cartType) {cartTypeText = ''}

    return (<div className="checkout-container order-status">

      <h1 className="cart-title">
        Order Confirmation
      </h1>

      {((this.state.order) && (paymentType === 'CARD')) ?
        <div className="credit-card-statement">
          <p className="charge-info">your payment is processed by:&nbsp;</p>
          <p className="charge-company">Hide the Llama, LLC</p>
          <div className="restaurant-statement">
             <span className="statement-text amount">${invoice}</span>&nbsp;
            <span className="statement-text descriptor">{this.state.order.statementDescriptor}</span>

          </div>
          {/* <div className="or-separator">
          or&nbsp;
          </div> */}
          {/* <div className="generic-statement">
          <span className="statement-text amount">${invoice}</span>&nbsp;
            <span className="statement-text descriptor">Hide the Llama</span>
          </div> */}
        </div> 
      : null}

      {/* <hr className="separator" /> */}

      {/* <h2 className="cart-sub-title">
        Email List
      </h2> */}

      <Email {...order} shopId={this.props.shopId} orderId={this.props.orderId}></Email>


   

      <p><span className="cart-type highlight">{cartTypeText}</span></p>

      {(invoice && paymentType === 'CARD' && !tips) && <Tips
        invoice={invoice}
        order_id={this.props.orderId}
        r_id={this.props.shopId}
        tips={tips}
      />}

      <h2 className="check-order-header">Check Your Order:</h2>
      <p>Call <a className="phone-link" href={`tel:${restaurantPhone}`}>{restaurantPhone}</a> for corrections.</p>

      <p>If you are unable to reach the restaurant you may try emailing the website manager: <a href={`mailto:${this.props.shopId}@hidethellama.com`}>{this.props.shopId}@hidethellama.com</a></p>


      <p><a href={`https://z049ngmfo5.execute-api.us-west-1.amazonaws.com/public/receipts?order_id=${this.props.orderId}&r_id=${this.props.shopId}`}>
                Printable Receipt
              </a></p>

      {this.state.order && <ErrorBoundary>
        <OrderReceipt order={this.state.order} />
      </ErrorBoundary>}



      {(invoice && paymentType === 'CARD' && tips) && <Tips
        invoice={invoice}
        order_id={this.props.orderId}
        r_id={this.props.shopId}
        tips={tips}
      />}

    </div>)
  }
}

export default injectShopContainer(OrderStatus)
